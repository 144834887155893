export const siteConfig = {
    name: "Stivor ETS | Associazione Stivor",
    description: "Sito ufficiale dell'associazione Stivor",
    navItems: [
        {
            label: "Il mio profilo",
            href: "/dashboard",
            loggedIn: true,
        },
        {
            label: "News",
            href: "/articles"
        },
        {
            label: "Chi siamo",
            href: "/about",
        },
        {
            label: "Documenti",
            href: "/documents",
            loggedIn: true,
        },
        {
            label: "Video",
            href: "/videos",
        },
        {
            label: "Proposte",
            href: "/ideas",
            loggedIn: true,
        },
        {
            label: "Archivio",
            href: "/archive",
        }
    ],
    links: {
        github: "https://github.com/gwabbey",
        website: "https://g3b.dev"
    },
};
