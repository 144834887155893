"use client";

import * as React from "react";
import {NextUIProvider} from "@nextui-org/react";
import {ThemeProvider as NextThemesProvider} from "next-themes";
import {ThemeProviderProps} from "next-themes/dist/types";
import {Toaster} from "react-hot-toast";
import {useRouter} from "next/navigation";
import {PayPalScriptProvider} from "@paypal/react-paypal-js";

export interface ProvidersProps {
    children: React.ReactNode;
    themeProps?: ThemeProviderProps;
}

const payPalOptions = {
    clientId: process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID as string,
    currency: "EUR",
    intent: "capture",
}

export function Providers({children, themeProps}: ProvidersProps) {
    const router = useRouter();
    return (
        <PayPalScriptProvider options={payPalOptions}>
            <NextUIProvider navigate={router.push}>
                <NextThemesProvider {...themeProps}>{children}</NextThemesProvider>
                <Toaster/>
            </NextUIProvider>
        </PayPalScriptProvider>
    );
}
