'use client'
import Link from 'next/link'
import {link as linkStyles} from "@nextui-org/theme";
import {siteConfig} from "@/config/site";
import clsx from "clsx";
import {ThemeSwitch} from "@/components/theme-switch";
import React from "react";
import {Role} from "@prisma/client";
import {
    Avatar,
    Badge,
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownTrigger,
    Navbar as NextNavbar,
    NavbarBrand,
    NavbarContent,
    NavbarItem,
    NavbarMenu,
    NavbarMenuItem,
    NavbarMenuToggle
} from "@nextui-org/react";
import {User} from "@/config/interfaces";
import {IconCoin, IconTool} from "@tabler/icons-react";
import {motion} from "framer-motion";

export default function Navbar({user}: { user: User }) {
    const isAdmin = user && user.role === Role.ADMIN;
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    const DonationLink = ({className, onPress}: { className?: string, onPress?: () => void }) => (
        <Button color="warning" variant="solid" className={className} isIconOnly={isAdmin && !isMenuOpen}
                as={Link} href="/donate"
                onPress={onPress}
                startContent={<IconCoin />}>
            {(!isAdmin || isMenuOpen && isAdmin) && 'Sostienici'}
        </Button>
    );

    const ManagementDropdown = ({onClick}: { onClick?: () => void }) => (
        <Dropdown>
            <NavbarItem>
                <DropdownTrigger>
                    <Button
                        isIconOnly={onClick === undefined}
                        disableRipple
                        className={clsx(
                            linkStyles({color: "foreground"}),
                            "p-0 font-bold bg-transparent data-[hover=true]:bg-transparent data-[active=true]:text-primary data-[active=true]:font-medium"
                        )}
                        startContent={<IconTool />}
                        radius="sm"
                    >
                        {onClick ? "Gestione" : ""}
                    </Button>
                </DropdownTrigger>
            </NavbarItem>
            <DropdownMenu
                aria-label="Gestione"
                itemClasses={{
                    base: "gap-4",
                }}
            >
                <DropdownItem as={Link} href="/users" onClick={onClick}>
                    Utenti
                </DropdownItem>
                <DropdownItem as={Link} href="/payments" onClick={onClick}>
                    Pagamenti
                </DropdownItem>
                <DropdownItem as={Link} href="/projects" onClick={onClick}>
                    Progetti
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );

    const profile = (
        <Link href={'/dashboard'}>
            <div className="flex items-center justify-center pt-2 sm:justify-end">
                <div className="flex gap-4">
                    <div className="flex flex-col gap-1 items-center sm:items-end justify-center">
                        <h4 className="text-small font-semibold leading-none text-default-600 line-clamp-1">{user?.firstName} {user?.lastName}</h4>
                        <h5 className="text-small tracking-tight text-default-400">{user?.cardCode}</h5>
                    </div>
                    <Badge color="success" content="">
                        <Avatar isBordered radius="full" size="sm" src="/blank.png" />
                    </Badge>
                </div>
            </div>
        </Link>
    );

    return (
        <NextNavbar maxWidth="xl" height="6rem" isMenuOpen={isMenuOpen}
                    onMenuOpenChange={setIsMenuOpen}>
            <NavbarContent className="basis-1/5 sm:basis-full" justify="start">
                <NavbarBrand className="gap-3 max-w-fit">
                    <Link className="flex justify-start items-center gap-1" href="/">
                        <p className="font-bold text-inherit">Štivor</p>
                    </Link>
                </NavbarBrand>
                <ul className="hidden sm:flex gap-4 justify-start items-center ml-2">
                    {siteConfig.navItems.map((item) => (
                        (!item.loggedIn || (item.loggedIn && user)) && (
                            <NavbarItem key={item.href}>
                                <Link
                                    className={clsx(
                                        linkStyles({color: "foreground"}),
                                        item.label === "Archivio" ? "text-warning-400" : "",
                                        "data-[active=true]:text-primary data-[active=true]:font-medium"
                                    )}
                                    color="foreground"
                                    href={item.href}
                                >
                                    {item.label}
                                    {item.label === "Archivio" && (
                                        <motion.div
                                            animate={{
                                                transition: {
                                                    default: {
                                                        repeat: Infinity,
                                                        duration: 2,
                                                        ease: "easeInOut"
                                                    }
                                                },
                                                scale: [1, 1.2, 1],
                                            }}
                                        >
                                            <div className="ml-2">🛠🚧</div>
                                        </motion.div>
                                    )}
                                </Link>
                            </NavbarItem>)
                    ))}
                </ul>
            </NavbarContent>

            <NavbarContent
                className="hidden sm:flex basis-1/5 sm:basis-full"
                justify="end"
            >
                <DonationLink className="hidden sm:flex" />

                {isAdmin && (
                    <ManagementDropdown />
                )}

                {user && (
                    <NavbarItem className="hidden lg:flex">{profile}</NavbarItem>
                )}
                <NavbarItem className="hidden sm:flex gap-2">
                    <ThemeSwitch />
                </NavbarItem>
            </NavbarContent>

            <NavbarContent className="sm:hidden basis-1 pl-4" justify="end">
                <ThemeSwitch />
                <NavbarMenuToggle />
            </NavbarContent>

            <NavbarMenu>
                {user && (<div onClick={() => {
                        setIsMenuOpen(!isMenuOpen)
                    }}>
                        {profile}
                    </div>
                )}
                <div className="mx-4 mt-2 flex flex-col gap-2 text-center">
                    {siteConfig.navItems.map((item, index) => (
                        (!item.loggedIn || (item.loggedIn && user)) && (
                            <NavbarMenuItem key={`${item}-${index}`}>
                                <Link
                                    color={index === siteConfig.navItems.length - 1
                                        ? "primary"
                                        : "foreground"
                                    }
                                    href={item.href}
                                    onClick={() => {
                                        setIsMenuOpen(!isMenuOpen)
                                    }}
                                >
                                    {item.label}
                                </Link>
                            </NavbarMenuItem>
                        )))}
                    {isAdmin && (
                        <div className="flex py-4 justify-center">
                            <ManagementDropdown onClick={() => {
                                setIsMenuOpen(!isMenuOpen)
                            }} />
                        </div>
                    )}
                    <DonationLink onPress={() => {
                        setIsMenuOpen(!isMenuOpen)
                    }} />
                </div>
            </NavbarMenu>
        </NextNavbar>
    );
};
